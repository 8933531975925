import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Image from "../components/image"

const BuildingSupplies = () => (
  <Layout>
    <SEO title="Building Supplies" />

    <div className="page-banner">
      <div className="container">
        <h1>
          Contact us
        </h1>
      </div>
    </div>

    <div className="banner-skew">
      <div className="skew-left"></div>
    </div>

    <div className="page-content">
      <div className="container">

        <h2>Contact us</h2>
        <div>
          <p>
            6 Clayton Street,
            New Market,
            Auckland, 1023,
            New Zealand
          </p>
          <p>
            <a href="mailto:info@gradea.co.nz">info@gradea.co.nz</a>
          </p>
        </div>

      </div>
    </div>

  </Layout>
)

export default BuildingSupplies
